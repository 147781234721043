import React, {useEffect, useRef, useState} from 'react';
import {Dimensions, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {request} from '../until/request'
import {Dialog, Portal} from 'react-native-paper';
import ItemDialog from '../components/orderComponents/itemDialog';
import OrderItemsTable from '../components/orderComponents/orderItemsTable';
import ScheduleTimeModal from '../components/orderComponents/ScheduleTimeModal';
import PaymentView from '../components/payment';
import * as cartAction from '../store/actions/cart';
import {useDispatch, useSelector} from 'react-redux';
import {AntDesign, Feather, Fontisto, MaterialCommunityIcons, MaterialIcons, SimpleLineIcons} from '@expo/vector-icons';
import ChangeOrderType from "../components/orderComponents/changeOrderType";

const Index = (props) => {
    const order = useSelector(state => state.cart);
    const [tip, setTip] = useState(order.tip);
    const [visible, setVisible] = useState(false);
    const [rightSideItemsShow, setRightSideItemsShow] = useState({
        tipModalVisible: false,
        showTimeVisible: false,
        showOrderType: false,
        showDiscount: false,
        showSelfInput: false,
        showPayment: false
    })
    const [categories, setCategories] = useState();
    const [specialCategories, setSpecialCategories] = useState();
    const [specialModifiers, setSpecialModifiers] = useState();
    const [currentCategoryIndex, setCategoryIndex] = useState(0);
    const [curruntItem, setCurrentItem] = useState();
    const [selectedRowId, setSelectedRowId] = useState();
    const [itemSearchResult, setItemSearchResult] = useState();
    const [selectedSpecialRequest, setSelectedSpacialRequest] = useState();
    const [specialRequestPhrase, setSpecialRequestPhrase] = useState({
        specialRequestModifier: null,
        specialRequestMaterial: null
    })
    const [calculatedOrder, setCalculatedOrder] = useState({
        subTotal: 0.00,
        total: 0.00,
        discount: 0.00,
        tip: 0.00,
        deliveryFee: 0.00,
        convenienceFee: 1.25,
    });
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const scrollRef = useRef();
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);
    let searchedItemsResult = [];
    const dateString = new Date(order.scheduleTime).toDateString();
    const timeString = new Date(order.scheduleTime);
    const [selfInputData, setSelfInputData] = useState({
        quantity: 1,
        price: '0.00',
        isOrder: true
    })
    const [orderType, setOrderType] = useState(order.orderType)
    const [addressVerified, setAddressVerified] = useState(false)
    const [customerAddress, setCustomerAddress] = useState(null)
    const [customerAddress2, setCustomerAddress2] = useState(null)
    const [customerCity, setCustomerCity] = useState(null)
    const [customerState, setCustomerState] = useState(null)
    const [customerZip, setCustomerZip] = useState(null)
    const [customerAddressInitialized, setCustomerAddressInitialized] = useState(false)
    const [deliveryFee, setDeliveryFee] = useState(null)
    const [verifyingAddress, setVerifyingAddress] = useState(false)
    const [addressActive, setAddressActive] = useState(false)
    const [addressList, setAddressList] = useState([])

    useEffect(() => {
        getStoreMenu();
        rePositionSelectedRow();

        if (localStorage.tpfRef) {
            let tpfRef = JSON.parse(localStorage.tpfRef);
            setCalculatedOrder(pre => {
                return {
                    ...pre,
                    deliveryFee: tpfRef.tdf
                }
            });
        }
        selectedRowId && scrollRef.current.scrollTo({ x: 0, y: 10000000, animated: false })
    }, []);

    useEffect(() => {
        if (specialRequestPhrase.specialRequestModifier && specialRequestPhrase.specialRequestMaterial) {
            const optionTobeAdded = {
                name: specialRequestPhrase.specialRequestModifier.name + ' ' + specialRequestPhrase.specialRequestMaterial.name,
                cnName: specialRequestPhrase.specialRequestModifier.cnName + specialRequestPhrase.specialRequestMaterial.cnName,
                price: specialRequestPhrase.specialRequestModifier.charge ? specialRequestPhrase.specialRequestMaterial.price : 0,
                quantity: 1,
            }
            selectedRowId && dispatch(cartAction.addOption(selectedRowId, optionTobeAdded));
            setSpecialRequestPhrase({
                specialRequestModifier: null,
                specialRequestMaterial: null
            })
        }

    }, [specialRequestPhrase]);

    let subTotal = 0.00;
    let tax = 0.00;
    order.orderItems.forEach(e => {
        let aItemSubtotal = e.price;
        e.orderItemsOptions.forEach(o => {
            console.log(o.price, o.quantity)
            aItemSubtotal = aItemSubtotal + o.price * o.quantity;
            console.log(aItemSubtotal);
        })
        subTotal = aItemSubtotal * e.quantity + subTotal;

    });

    tax = subTotal * props.route.params.restaurant.taxRate;
    const rePositionSelectedRow = (i = 1) => {
        order.orderItems.length > 0 && setSelectedRowId((order.orderItems.length - i).toString() + `,${order.orderItems[order.orderItems.length - 1].orderItemsOptions.length - 1}`);
    }

    const getStoreMenu = async () => {
        await request(`/secure/api/tapthru/getSpecialRequestCategories?restaurantInfoId=${props.route.params.restaurant.id}`, {
            method: 'POST'
        }).then(respone => {
            return respone.json()
        })
            .then(responeText => {
                setSpecialCategories(responeText);
            })
        await request(`/secure/api/tapthru/getStoreMenu?restaurantInfoId=${props.route.params.restaurant.id}`, {
            method: 'POST'
        }).then(respone => respone.json())
            .then(responeText => {
                setCategories(responeText);
            })
        await request(`/secure/api/tapthru/getSpecialRequestModifiers?restaurantInfoId=${props.route.params.restaurant.id}`, {
            method: 'POST'
        }).then(respone => respone.json())
            .then(responeText => {
                setSpecialModifiers(responeText);
            })

    }

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ampm;
        return strTime;
    }

    const sendMessage = (m, messageType) => {
        setMessage(m);
        let messageColor;
        if (messageType) {
            switch (messageType) {
                case 'SUCCESS':
                    messageColor = '#98ff98';
                    break;
                default:
                    messageColor = '#FFFF6AFF';
                    break;
            }
        } else {
            messageColor = '#FFFF6AFF';
        }
        document.getElementById('messageView').style.backgroundColor = messageColor;
        document.getElementById('messageView').style.top = '0px';
        setTimeout(() => {
            document.getElementById('messageView').style.top = '-120px'
            setMessage('')
        }, 2000)
    }

    const addSelfInput = () => {
        const data = selfInputData
        if (data.isOrder) {
            var p = {
                restaurantInfoId: props.route.params.restaurant.id,
                quantity: data.quantity,
                name: data.name,
                cnName: data.name,
                price: parseFloat(data.price),
                itemId: null,
                orderItemsOptions: [],
                categoryId: null,
                categoryName: '',
                categoryCnName: '',
                specialInstructions: null,
                printerName: null,
                printable: true,
                mOrderItem: null,
                taxExempt: false
            }
            dispatch(cartAction.addToCart(p));
            setTimeout(() => {
                scrollRef.current.scrollTo({ x: 0, y: 10000000 })
            }, 0);
            setSelfInputData({
                quantity: 1,
                price: '0.00',
                isOrder: true
            })
        } else {
            var op = {
                name: data.name,
                cnName: data.name,
                price: parseFloat(data.price),
                quantity: data.quantity,
                categoryId: null,
                categoryName: null,
                categoryCnName: null
            }
            selectedRowId && dispatch(cartAction.addOption(selectedRowId, op))
            setSelfInputData({
                quantity: 1,
                price: '0.00',
                isOrder: true
            })
        }

        setRightSideItemsShow(prevState => {
            return {
                ...prevState,
                showOrderType: false,
                tipModalVisible: false,
                showTimeVisible: false,
                showDiscount: false,
                showSelfInput: false,
                showPayment: false
            }
        })
    }

    console.log('rendering');

    return (
        <View style={styles.container}>
            <View nativeID='messageView' style={{
                position: 'fixed',
                width: 500,
                height: 100,
                top: -120,
                backgroundColor: 'rgb(255, 255, 106)',
                zIndex: 1000000,
                left: 'calc(50% - 250px)',
                borderBottomLeftRadius: 25,
                borderBottomRightRadius: 25,
                transition: 'top 1s',
                boxShadow: 'rgb(0 0 0 / 30%) -8px 14px 20px 2px',
                justifyContent: 'center'
            }}>
                <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 17 }}>{message}</Text>
            </View>
            <View style={{ flexDirection: 'row', backgroundColor: 'white', height: 47, alignItems: 'center', paddingHorizontal: 16, justifyContent: 'space-between', zIndex: 100, borderBottomWidth: 1, borderColor: '#6666' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }} onPress={() => props.navigation.goBack()}>
                        <AntDesign name='arrowleft' size={22} color='#DB4437' />
                        <Text style={{ color: '#DB4437', fontWeight: 'bold', fontSize: 16, marginLeft: 4 }}>
                            Back
                    </Text>
                    </TouchableOpacity>
                    <Text style={{ marginLeft: 16, fontWeight: 'bold', fontSize: 16 }}>{order.orderType == 'DELIVERY' ? 'Delivery' : 'Pickup'} for ({order.scheduleTime ? dateString.split(' ')[1] + ' ' + dateString.split(' ')[2] + ', ' + formatAMPM(timeString) : 'ASAP'})</Text>
                </View>

                <View style={{ flex: 1, width: 200, position: 'absolute', left: 'calc(50% - 100px)' }}>
                    <Text style={{ textAlign: 'center', fontSize: 18, color: '#DB4437', fontWeight: 'bold' }}>TAPTHRU ORDERING</Text>
                </View>

                <View style={{ zIndex: 1000, position: 'absolute', right: 20 }}>
                    <View style={{ width: 250, flexDirection: 'row', borderWidth: 2, borderColor: '#6666', borderRadius: 10000, paddingVertical: 4, paddingHorizontal: 8 }}>
                        <AntDesign name='search1' size={24} color='#494949' />
                        <TextInput style={{ flex: 1, fontWeight: '500', outline: 'none', marginLeft: 4 }} placeholder='Search items'
                            onChange={event => {
                                if (event.nativeEvent.text !== '') {
                                    categories.forEach(e => {
                                        e.items.forEach(i => {
                                            if (i.name.toLowerCase().includes(event.nativeEvent.text.toLowerCase())) {
                                                searchedItemsResult.push(i);
                                            }
                                        })
                                    })
                                    setItemSearchResult(searchedItemsResult);
                                } else {
                                    setItemSearchResult([]);
                                }

                            }}
                        />
                    </View>
                    {itemSearchResult && itemSearchResult.length > 0 &&
                        <View
                            style={{
                                position: 'absolute',
                                top: 40, left: 0,
                                backgroundColor: 'white',
                                zIndex: 1000,
                                width: '100%',
                                height: 500,
                                borderWidth: 1,
                                borderRadius: 8,
                                borderColor: 'rgb(231, 231, 231)',
                                boxShadow: 'rgb(0 0 0 / 20%) -12px 20px 20px 0px'
                            }}>
                            <View style={{ flexDirection: 'row', paddingVertical: 10 }}>
                                <TouchableOpacity onPress={() => setItemSearchResult(null)} style={{ position: 'absolute', left: 10, topL: 0 }}>
                                    <AntDesign name='closecircleo' size={22} color='#888' />
                                </TouchableOpacity>
                                <Text style={{ fontWeight: 'bold', fontSize: 13, flex: 1, textAlign: 'center', color: '#888', marginTop: 5 }}>{itemSearchResult.length} results</Text>
                            </View>
                            <ScrollView
                                style={{
                                    backgroundColor: 'white',
                                    zIndex: 1000,
                                    width: '100%',
                                    height: '100%',
                                }}>
                                {itemSearchResult.map((e, i) => {
                                    return (
                                        <TouchableOpacity style={{ borderBottomWidth: 1, borderColor: '#6666', padding: 16, }} key={'si' + i.toString()}
                                            onPress={() => {
                                                setCurrentItem(e);
                                                if (e.optionsList.length > 0) {
                                                    showModal();
                                                } else {
                                                    const itemToBeAdded = {
                                                        restaurantInfoId: e.restaurantInfoId,
                                                        quantity: 1,
                                                        name: e.name,
                                                        cnName: e.cnName,
                                                        price: e.price,
                                                        itemId: e.id,
                                                        orderItemsOptions: [],
                                                        categoryId: e.categoryId,
                                                        categoryName: categories[currentCategoryIndex].categoryName,
                                                        categoryCnName: categories[currentCategoryIndex].categoryCnName,
                                                        specialInstructions: null,
                                                        printerName: e.printerName,
                                                        printable: e.printable,
                                                        mOrderItem: null,
                                                        taxExempt: false
                                                    }
                                                    dispatch(cartAction.addToCart(itemToBeAdded));
                                                    setTimeout(() => {
                                                        scrollRef.current.scrollTo({ x: 0, y: 10000000 })
                                                    }, 0);
                                                }
                                                setItemSearchResult([]);
                                            }}
                                        >
                                            <Text style={{ fontWeight: 'bold', fontSize: 15 }}>{e.name}</Text>
                                            <Text style={{ fontWeight: '500', fontSize: 13, color: 'gray', marginTop: 8 }}>{e.categoryName}</Text>
                                        </TouchableOpacity>
                                    )
                                })}
                            </ScrollView></View>}
                </View>
            </View>

            <View style={{ flexDirection: 'row', flex: 1, padding: 16, maxWidth: 1400, width: '100%', alignSelf: 'center' }}>

                {/* left menu view */}
                <View style={{ flex: 1, height: '100%', width: '100%', backgroundColor: 'white', flexDirection: 'row' }}>

                    {/* category & special request category view */}
                    <View style={{ maxWidth: 212, height: '100%', borderTopLeftRadius: 25, borderBottomLeftRadius: 25, backgroundColor: 'rgb(66, 133, 244)', paddingTop: 25 }}>
                        <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }} contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                            {categories && categories.map((c, i) => {
                                return (
                                    <TouchableOpacity key={'c' + i.toString()} style={{ backgroundColor: currentCategoryIndex == i ? 'white' : 'rgb(66, 133, 244)', marginTop: (i == 0 || i == 1) && 0, marginLeft: i % 2 == 0 ? 0 : 12, borderTopLeftRadius: i % 2 !== 0 && 25, borderBottomLeftRadius: i % 2 !== 0 && 25, borderTopRightRadius: i % 2 == 0 && 25, borderBottomRightRadius: i % 2 == 0 && 25, marginVertical: 25, width: 100, height: 70, alignItems: 'center', justifyContent: 'center', borderColor: 'red', }}
                                        onPress={() => {
                                            setCategoryIndex(i);
                                            setSelectedSpacialRequest(null);
                                        }}
                                    >
                                        <Text style={{ textAlign: 'center', fontWeight: 'bold', color: currentCategoryIndex == i ? '#494949' : 'white' }}>{c.name}</Text>
                                    </TouchableOpacity>
                                )
                            })}
                        </ScrollView>

                        <View style={{ height: 185, flexWrap: 'wrap', borderTopWidth: 2, borderColor: 'white', marginLeft: 16, marginRight: 16, marginBottom: 11, paddingTop: 11 }}>
                            {specialCategories && specialCategories.map((c, i) => {
                                if (i < 4) {
                                    return (
                                        <TouchableOpacity key={'sp' + i.toString()} style={{ width: 75, height: 75, backgroundColor: 'white', margin: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 1000, borderWidth: 1, borderColor: '#6666' }}
                                            onPress={() => {
                                                setSelectedSpacialRequest(c);
                                            }}
                                        >
                                            <Text style={{ textAlign: 'center', fontWeight: 'bold', color: '#494949' }}>{c.name}</Text>
                                        </TouchableOpacity>
                                    )
                                }
                            })}
                        </View>
                    </View>

                    {/* Item view */}
                    <View style={{ flex: 1, height: '100%', backgroundColor: 'white', alignItems: 'center', borderColor: '#8888', borderTopWidth: 1, borderBottomWidth: 1, }}>
                        {selectedSpecialRequest &&
                            <View style={{ padding: 20, alignItems: 'center' }}>
                                {!selectedRowId && <Text style={{ fontWeight: 'bold', fontSize: 18, color: 'red', marginBottom: 16 }}>Must select an item to add special request</Text>}
                                <Text style={{ fontWeight: 'bold', fontSize: 18 }}>{specialRequestPhrase.specialRequestModifier !== null ? specialRequestPhrase.specialRequestModifier.name + ' + ' : '________  + '}  {specialRequestPhrase.specialRequestMaterial != null ? specialRequestPhrase.specialRequestMaterial.name : '________'}</Text>
                            </View>}
                        <ScrollView contentContainerStyle={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }} showsVerticalScrollIndicator={false}>

                            {selectedSpecialRequest ? selectedSpecialRequest.specialRequestMaterials.map((e, i) => {
                                if (e.active) {
                                    return (

                                        <TouchableOpacity key={'item' + i.toString()} style={{ width: 100, height: 70, backgroundColor: '#4285F4', margin: 25, alignItems: 'center', justifyContent: 'center', padding: 10, borderWidth: 1, borderColor: '#8888', borderRadius: 12, marginBottom: 20 }}
                                            onPress={() => {
                                                setSpecialRequestPhrase(prevState => {
                                                    return { ...prevState, specialRequestMaterial: e }
                                                })
                                            }}
                                        >
                                            <Text style={{ textAlign: 'center', fontWeight: 'bold', color: 'white' }}>{e.name}</Text>
                                        </TouchableOpacity>
                                    )
                                }
                            }) :
                                categories && categories[currentCategoryIndex] && categories[currentCategoryIndex].items && categories[currentCategoryIndex].items.map((e, i) => {


                                    return (
                                        <TouchableOpacity key={'item' + i.toString()} style={{ width: 150, height: 70, backgroundColor: '#4285F4', margin: 25, alignItems: 'center', justifyContent: 'center', padding: 10, borderWidth: 1, borderColor: '#8888', borderRadius: 12, marginBottom: 20 }}
                                            onPress={() => {
                                                setCurrentItem(e);
                                                if (e.optionsList.length > 0) {
                                                    showModal();
                                                } else {
                                                    const itemToBeAdded = {
                                                        restaurantInfoId: e.restaurantInfoId,
                                                        quantity: 1,
                                                        name: e.name,
                                                        cnName: e.cnName,
                                                        price: e.price,
                                                        itemId: e.id,
                                                        orderItemsOptions: [],
                                                        categoryId: e.categoryId,
                                                        categoryName: categories[currentCategoryIndex].categoryName,
                                                        categoryCnName: categories[currentCategoryIndex].categoryCnName,
                                                        specialInstructions: null,
                                                        printerName: e.printerName,
                                                        printable: e.printable,
                                                        mOrderItem: null,
                                                        taxExempt: false
                                                    }
                                                    dispatch(cartAction.addToCart(itemToBeAdded));
                                                    setTimeout(() => {
                                                        scrollRef.current.scrollTo({ x: 0, y: 10000000 })
                                                        // props.rePositionSelectedRow(0);
                                                    }, 0);
                                                }

                                            }}
                                        >
                                            <Text style={{ textAlign: 'center', fontWeight: 'bold', color: 'white' }}>{e.name}</Text>
                                        </TouchableOpacity>
                                    )

                                })}

                        </ScrollView>
                    </View>

                    {/* options view */}
                    <View style={{ height: '100%', width: 116, backgroundColor: 'white', borderWidth: 1, borderColor: '#8888', borderRadius: 0, marginRight: 16, padding: 8, paddingVertical: 25 }}>
                        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ width: 100, alignItems: 'center', height: '100%' }} showsVerticalScrollIndicator={false}>
                            {selectedSpecialRequest ?
                                specialModifiers.map((e, i) => {
                                    return (
                                        <TouchableOpacity key={'spm' + i.toString()} style={{ width: 90, height: 90, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', marginBottom: 16 }}
                                            onPress={() => {
                                                setSpecialRequestPhrase(prevState => {
                                                    return { ...prevState, specialRequestModifier: e }
                                                })
                                            }}
                                        >
                                            <Text style={{ textAlign: 'center', fontWeight: 'bold', color: '#494949', borderBottomWidth: 2, paddingBottom: 8, borderColor: 'red' }}>{e.name}</Text>
                                        </TouchableOpacity>
                                    )
                                })

                                :
                                categories && categories[currentCategoryIndex] && categories[currentCategoryIndex].categoryOptionGroups && categories[currentCategoryIndex].categoryOptionGroups.map((og, i) => {
                                    return (
                                        og.options.map((o, j) => {
                                            return (
                                                <TouchableOpacity key={'og' + j.toString()} style={{ width: 90, height: 90, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center', marginBottom: 16 }}
                                                    onPress={() => {
                                                        const optionTobeAdded = {
                                                            name: o.name,
                                                            cnName: o.cnName,
                                                            price: o.price,
                                                            quantity: 1,
                                                            categoryId: categories[currentCategoryIndex].id,
                                                            categoryName: categories[currentCategoryIndex].name,
                                                            categoryCnName: categories[currentCategoryIndex].cnName
                                                        }
                                                        selectedRowId && dispatch(cartAction.addOption(selectedRowId, optionTobeAdded));
                                                    }}
                                                >
                                                    <Text style={{ textAlign: 'center', fontWeight: 'bold', color: '#494949', borderBottomWidth: 2, paddingBottom: 8, borderColor: 'red' }}>{o.name}</Text>
                                                </TouchableOpacity>
                                            )
                                        })
                                    )

                                })
                            }

                        </ScrollView>

                    </View>

                </View>

                {/* right operation view */}
                <View style={{ height: '100%', backgroundColor: 'white', minWidth: 400, flexDirection: 'row' }}>

                    {/* order Item table */}
                    <View style={{ flex: 1, backgroundColor: 'white' }}>
                        <OrderItemsTable setSelectedRowId={setSelectedRowId} selectedRowId={selectedRowId} scrollRef={scrollRef} />
                        <View style={{ flex: 1, backgroundColor: 'white', borderLeftWidth: 1, borderColor: '#8888', borderBottomWidth: 1 }}>
                            <View style={{ backgroundColor: 'white', height: 114, zIndex: 0, paddingTop: 24, paddingBottom: 16, marginHorizontal: 20, flexDirection: 'row', borderBottomWidth: 1, borderColor: '#8888' }}>
                                <View style={{ width: '50%', justifyContent: 'space-between' }}>
                                    {calculatedOrder.discount !== 0 && <View style={{ flexDirection: 'row', }}>
                                        <Text style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>Discount:</Text>
                                        <Text style={{ width: '40%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>$111.25</Text>
                                    </View>}
                                    {calculatedOrder.convenienceFee > 0 && <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>Conv. Fees:</Text>
                                        <Text style={{ width: '40%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>$1.25</Text>
                                    </View>}
                                    {calculatedOrder.deliveryFee > 0 && <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>DeliveryFee:</Text>
                                        <Text style={{ width: '40%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>${calculatedOrder.deliveryFee.toFixed(2)}</Text>
                                    </View>}
                                </View>

                                <View style={{ flex: 1, justifyContent: 'space-between' }}>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ width: '50%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>Sub:</Text>
                                        <Text style={{ width: '50%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>${subTotal.toFixed(2)}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ width: '50%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>Tax:</Text>
                                        <Text style={{ width: '50%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>${tax.toFixed(2)}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Text style={{ width: '50%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>Tips:</Text>
                                        <Text style={{ width: '50%', textAlign: 'right', fontWeight: 'bold', color: '#494949' }}>${(order && order.tip && Number(order.tip).toFixed(2)) || "0.00"}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ alignItems: 'flex-end', marginTop: 16 }}>
                                <View style={{ flexDirection: 'row', marginRight: 20 }}>
                                    <Text style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', fontSize: 18 }}>Total:</Text>
                                    <Text style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', fontSize: 18, marginLeft: 8 }}>${order.orderItems.length > 0 ? (subTotal + tax + Number(order.tip) + calculatedOrder.convenienceFee + calculatedOrder.deliveryFee).toFixed(2) : '0.00'}</Text>
                                </View>
                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20, marginTop: 10 }}>
                                <TouchableOpacity style={{ height: 66, width: 66, borderRadius: 16, backgroundColor: 'rgb(66, 133, 244)', alignItems: 'center', justifyContent: 'center' }}
                                    onPress={() => sendMessage('Save function only works in local store')}
                                >
                                    <AntDesign name='save' size={30} color='white' />
                                    <Text style={{ fontWeight: 'bold', color: 'white' }}>Save</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ height: 66, width: 66, borderRadius: 16, backgroundColor: 'rgb(66, 133, 244)', alignItems: 'center', justifyContent: 'center' }}
                                    onPress={() => sendMessage('Send to sections function only works in local store')}
                                >
                                    <Feather name='send' size={30} color='white' />
                                    <Text style={{ fontWeight: 'bold', color: 'white' }}>Send</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ height: 66, width: 66, borderRadius: 16, backgroundColor: 'rgb(66, 133, 244)', alignItems: 'center', justifyContent: 'center' }}
                                    onPress={() => sendMessage('Printing function only works in local store')}
                                >
                                    <Feather name='printer' size={30} color='white' />
                                    <Text style={{ fontWeight: 'bold', color: 'white' }}>Print</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>

                    {/* 最右边的view */}
                    <View style={{ height: '100%' }}>
                        <View style={{ backgroundColor: '#4285F4', alignItems: 'center', paddingRight: 16, paddingTop: 26, borderTopRightRadius: 25, marginLeft: 0, maxHeight: 573, height: '100%', }}>


                            <TouchableOpacity style={styles.operationButtons}
                                onPress={() => {

                                }}>
                                <Fontisto name='language' size={30} color='#494949' />
                                <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 4, color: '#494949' }}>中文</Text>
                            </TouchableOpacity>

                            <View>
                                <ChangeOrderType
                                    orderType={orderType}
                                    setOrderType={setOrderType}
                                    dispatch={dispatch}
                                    setCalculatedOrder={setCalculatedOrder}
                                    setRightSideItemsShow={setRightSideItemsShow}
                                    rightSideItemsShow={rightSideItemsShow}
                                    order={order}
                                    navigation={props.navigation}
                                    restaurant={props.route.params.restaurant}
                                    addressVerified={addressVerified}
                                    setAddressVerified={setAddressVerified}

                                    customerAddress={customerAddress}
                                    setCustomerAddress={setCustomerAddress}
                                    customerAddress2={customerAddress2}
                                    setCustomerAddress2={setCustomerAddress2}
                                    customerCity={customerCity}
                                    setCustomerCity={setCustomerCity}
                                    customerState={customerState}
                                    setCustomerState={setCustomerState}
                                    customerZip={customerZip}
                                    setCustomerZip={setCustomerZip}
                                    customerAddressInitialized={customerAddressInitialized}
                                    setCustomerAddressInitialized={setCustomerAddressInitialized}
                                    deliveryFee={deliveryFee}
                                    setDeliveryFee={setDeliveryFee}
                                    verifyingAddress={verifyingAddress}
                                    setVerifyingAddress={setVerifyingAddress}
                                    addressActive={addressActive}
                                    setAddressActive={setAddressActive}
                                    addressList={addressList}
                                    setAddressList={setAddressList}
                                    appleMaps={appleMaps}
                                    sendMessage={sendMessage}
                                />

                                <TouchableOpacity style={[styles.operationButtons, { backgroundColor: rightSideItemsShow.showOrderType ? 'black' : 'white' }]}
                                    onPress={() => {
                                        if (!rightSideItemsShow.showOrderType) {
                                            setOrderType(order.orderType);
                                            setCustomerAddressInitialized(false);
                                            setCustomerAddress(order.customer.address);
                                            setCustomerAddress2(order.customer.address2);
                                            setCustomerCity(order.customer.city);
                                            setCustomerState(order.customer.state);
                                            setCustomerZip(order.customer.zip);
                                            setCustomerAddressInitialized(true);
                                            setAddressVerified(order.orderType === 'PICK_UP');
                                        }
                                        setRightSideItemsShow(prevState => {
                                            const show = prevState.showOrderType;
                                            return {
                                                ...prevState, showOrderType: !show,
                                                tipModalVisible: false,
                                                showTimeVisible: false,
                                                showDiscount: false,
                                                showSelfInput: false,
                                                showPayment: false
                                            }
                                        })
                                    }}>
                                    <MaterialIcons name='delivery-dining' size={30} color={rightSideItemsShow.showOrderType ? 'white' : '#494949'} />
                                    <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 4, color: rightSideItemsShow.showOrderType ? 'white' : '#494949' }}>TYPE</Text>
                                </TouchableOpacity>
                            </View>


                            <View>
                                <View style={{ zIndex: 10000, display: rightSideItemsShow.showTimeVisible ? 'flex' : 'none' }}>
                                    <View style={{ position: 'absolute', left: -5, top: 15, height: 30, borderBottomWidth: 20, borderLeftWidth: 20, borderTopWidth: 20, borderBottomColor: 'transparent', borderTopColor: 'transparent', borderLeftColor: '#4285F4', zIndex: 100000 }}>
                                    </View>
                                    <View style={{ position: 'absolute', left: -318, top: 0, backgroundColor: '#4285F4', zIndex: 100000, height: 246, maxWidth: 317, width: 1000, padding: 16, borderBottomLeftRadius: 25, boxShadow: 'rgb(0 0 0 / 30%) -8px 14px 20px 2px' }}>
                                        {rightSideItemsShow.showTimeVisible && <ScheduleTimeModal show={rightSideItemsShow.showTimeVisible}
                                            updateShow={() => {
                                                setRightSideItemsShow(prevState => {
                                                    const show = prevState.showTimeVisible;

                                                    return {
                                                        ...prevState,
                                                        showTimeVisible: false,
                                                        showOrderType: false,
                                                        tipModalVisible: false,
                                                        showDiscount: false,
                                                        showSelfInput: false,
                                                        showPayment: false
                                                    }
                                                })
                                            }}
                                            orderType={order.orderType == 'DELIVERY' ? 'Delivery' : 'Pickup'} />}
                                    </View>
                                </View>

                                <TouchableOpacity style={[styles.operationButtons, { backgroundColor: rightSideItemsShow.showTimeVisible ? 'black' : 'white' }]}
                                    onPress={() => {
                                        setRightSideItemsShow(prevState => {
                                            const show = prevState.showTimeVisible;

                                            return {
                                                ...prevState,
                                                showTimeVisible: !show,
                                                showOrderType: false,
                                                tipModalVisible: false,
                                                showDiscount: false,
                                                showSelfInput: false,
                                                showPayment: false
                                            }
                                        })
                                    }}>
                                    <AntDesign name='clockcircleo' size={30} color={rightSideItemsShow.showTimeVisible ? 'white' : '#494949'} />
                                    <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 4, color: rightSideItemsShow.showTimeVisible ? 'white' : '#494949' }}>TIME</Text>
                                </TouchableOpacity>
                            </View>




                            <View>
                                <View style={{ zIndex: 10000, display: rightSideItemsShow.tipModalVisible ? 'flex' : 'none' }}>
                                    <View style={{ position: 'absolute', left: -5, top: 15, height: 30, borderBottomWidth: 20, borderLeftWidth: 20, borderTopWidth: 20, borderBottomColor: 'transparent', borderTopColor: 'transparent', borderLeftColor: '#4285F4', zIndex: 100000 }}>
                                    </View>
                                    <View style={{ position: 'absolute', left: -318, top: 0, backgroundColor: '#4285F4', zIndex: 100000, height: 157, maxWidth: 317, width: 1000, padding: 16, borderBottomLeftRadius: 25, boxShadow: 'rgb(0 0 0 / 30%) -8px 14px 20px 2px' }}>
                                        <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 25 }}>Add Tips</Text>
                                        <View style={{ alignSelf: 'center' }}>

                                            <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 24 }}>
                                                <Text style={{ fontSize: 20, fontWeight: 'bold', alignSelf: 'flex-end', marginBottom: 20, color: 'white' }}>$</Text>
                                                <TextInput
                                                    style={{ textAlign: 'center', marginBottom: 15, padding: 5, fontWeight: 'bold', borderBottomWidth: 1, borderColor: 'white', outline: 'none', fontSize: 20, color: 'white', width: '100%', marginHorizontal: 10 }}
                                                    value={tip}
                                                    onChange={e => {
                                                        // only allow number and .
                                                        let text = e.nativeEvent.text.replace(/[^0-9\.]/g, '');
                                                        // if first number is zero, once entry, remove the leading zero
                                                        if (text.length == 2 && text[0] == '0') text = e.nativeEvent.data;
                                                        // only 1 . can be entered!
                                                        if (e.nativeEvent.data == '.') {
                                                            if (text.split('.').length > 2) {
                                                                text = text.slice(0, -1);
                                                            } else {
                                                                if (text.split('.')[0] == '') {
                                                                    text = '0.'
                                                                }
                                                            }

                                                        }
                                                        // only allow 2 digit after .
                                                        if (text && text.split('.').length == 2) {
                                                            if (text.split('.')[1].length > 2) {
                                                                text = text.slice(0, -1)
                                                            }
                                                        }
                                                        if (!text) {
                                                            text = "0.00";
                                                        }
                                                        setTip(text);
                                                    }}
                                                    onSubmitEditing={() => {
                                                        dispatch(cartAction.updateTips(parseFloat(tip)));
                                                        setRightSideItemsShow(prevState => {
                                                            const show = prevState.tipModalVisible;
                                                            return {
                                                                ...prevState,
                                                                showOrderType: false,
                                                                tipModalVisible: false,
                                                                showTimeVisible: false,
                                                                showDiscount: false,
                                                                showSelfInput: false,
                                                                showPayment: false
                                                            }
                                                        })
                                                    }}
                                                />

                                                <TouchableOpacity style={{ backgroundColor: 'black', paddingVertical: 6, paddingHorizontal: 16, borderRadius: 1000, maxHeight: 37, marginBottom: 10 }}
                                                    onPress={() => {
                                                        // saveTips();
                                                        dispatch(cartAction.updateTips(parseFloat(tip)));
                                                        setRightSideItemsShow(prevState => {
                                                            const show = prevState.tipModalVisible;
                                                            return {
                                                                ...prevState,
                                                                showOrderType: false,
                                                                tipModalVisible: !show,
                                                                showTimeVisible: false,
                                                                showDiscount: false,
                                                                showSelfInput: false,
                                                                showPayment: false
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <Text style={{ color: 'white', fontWeight: 'bold', marginBottom: 5 }}>Apply</Text>
                                                </TouchableOpacity>
                                            </View>

                                        </View>
                                        <View style={{ flexDirection: 'row', paddingVertical: 10, paddingHorizontal: 25, justifyContent: 'center', alignItems: 'center' }}>

                                        </View>
                                    </View>
                                </View>

                                <TouchableOpacity style={[styles.operationButtons, { backgroundColor: rightSideItemsShow.tipModalVisible ? 'black' : 'white' }]}
                                    onPress={() => {
                                        setRightSideItemsShow(prevState => {
                                            const show = prevState.tipModalVisible;
                                            return {
                                                ...prevState,
                                                showOrderType: false,
                                                tipModalVisible: !show,
                                                showTimeVisible: false,
                                                showDiscount: false,
                                                showSelfInput: false,
                                                showPayment: false
                                            }
                                        })
                                    }}>
                                    <Feather name='dollar-sign' size={30} color={rightSideItemsShow.tipModalVisible ? 'white' : '#494949'} />
                                    <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 4, color: rightSideItemsShow.tipModalVisible ? 'white' : '#494949' }}>TIPS</Text>
                                </TouchableOpacity>
                            </View>



                            <TouchableOpacity
                                style={styles.operationButtons}
                                onPress={() => {
                                    sendMessage('Discount function only works in local store')
                                }}>
                                <Feather name='percent' size={30} color='#494949' />
                                <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 4, color: '#494949' }}>OFF</Text>
                            </TouchableOpacity>
                        </View>

                        {/* bottom right view */}
                        <View style={{ flex: 1, backgroundColor: '#4285F4', borderBottomRightRadius: 25, justifyContent: 'flex-end' }}>

                            <View style={{ display: rightSideItemsShow.showSelfInput ? 'flex' : 'none', zIndex: 100000 }} >
                                <View style={{ position: 'absolute', left: -5, top: 15, height: 30, borderBottomWidth: 20, borderLeftWidth: 20, borderTopWidth: 20, borderBottomColor: 'transparent', borderTopColor: 'transparent', borderLeftColor: '#4285F4', zIndex: 100000 }}>
                                </View>
                                <View style={{ position: 'absolute', left: -318, top: 0, backgroundColor: '#4285F4', zIndex: 100000, minHeight: 157, maxWidth: 317, width: 1000, padding: 16, borderBottomLeftRadius: 25, boxShadow: 'rgb(0 0 0 / 30%) -8px 14px 20px 2px' }} >
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                                        <Text style={{ color: 'white', fontWeight: 'bold', fontSize: 18, marginBottom: 8 }}>Self Input</Text>
                                        <View style={{ flexDirection: 'row' }} >
                                            <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', marginRight: 8 }}
                                                onPress={() => {
                                                    setSelfInputData(pre => {
                                                        return {
                                                            ...pre,
                                                            isOrder: true
                                                        }
                                                    })
                                                }}
                                            >
                                                <MaterialCommunityIcons name={selfInputData.isOrder ? 'checkbox-marked' : 'checkbox-blank-outline'} size={24} color='#fff' />
                                                <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold' }} >Item</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center' }}
                                                onPress={() => {
                                                    setSelfInputData(pre => {
                                                        return {
                                                            ...pre,
                                                            isOrder: false
                                                        }
                                                    })
                                                }}
                                            >
                                                <MaterialCommunityIcons name={!selfInputData.isOrder ? 'checkbox-marked' : 'checkbox-blank-outline'} size={24} color='#fff' />
                                                <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold' }} >Option</Text>
                                            </TouchableOpacity>
                                        </View>

                                    </View>

                                    <View style={{ flexDirection: 'row', alignItems: 'center' }} >
                                        <Text style={{ fontSize: 16, fontWeight: 'bold', alignSelf: 'flex-end', marginBottom: 20, color: 'white' }}>Name</Text>
                                        <TextInput
                                            style={{ marginBottom: 15, padding: 5, fontWeight: 'bold', borderBottomWidth: 1, borderColor: 'white', outline: 'none', fontSize: 16, color: 'white', width: '100%', marginHorizontal: 10 }}
                                            onChangeText={text => setSelfInputData(pre => { return { ...pre, name: text } })}
                                            value={selfInputData.name}
                                        />
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 8 }} >
                                        <Text style={{ fontSize: 16, fontWeight: 'bold', alignSelf: 'flex-end', marginBottom: 20, color: 'white' }}>Price</Text>
                                        <TextInput
                                            style={{ marginBottom: 15, padding: 5, fontWeight: 'bold', borderBottomWidth: 1, borderColor: 'white', outline: 'none', fontSize: 16, color: 'white', width: '100%', marginHorizontal: 10 }}
                                            value={selfInputData.price}
                                            onChangeText={text => setSelfInputData(pre => { return { ...pre, price: text } })}
                                        />
                                        <TouchableOpacity style={{ backgroundColor: '#fff', borderRadius: 10000 }} onPress={() => setSelfInputData(pre => { return { ...pre, price: '1.00' } })} >
                                            <Text style={{ paddingHorizontal: 10, paddingVertical: 14, color: '#000', fontWeight: 'bold', fontSize: 16 }} >1.00</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ backgroundColor: '#fff', borderRadius: 10000, marginLeft: 8 }} onPress={() => setSelfInputData(pre => { return { ...pre, price: '2.00' } })}>
                                            <Text style={{ paddingHorizontal: 10, paddingVertical: 14, color: '#000', fontWeight: 'bold', fontSize: 16 }} >2.00</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', }} >
                                        <Text style={{ fontSize: 16, fontWeight: 'bold', alignSelf: 'flex-end', marginBottom: 20, color: 'white' }}>Quantity</Text>
                                        <TextInput
                                            style={{ marginBottom: 15, padding: 5, fontWeight: 'bold', borderBottomWidth: 1, borderColor: 'white', outline: 'none', fontSize: 16, color: 'white', width: '100%', marginHorizontal: 10 }}
                                            value={selfInputData.quantity}
                                            onChangeText={text => setSelfInputData(pre => { return { ...pre, quantity: text } })}
                                        />
                                        <TouchableOpacity style={{ backgroundColor: '#fff', borderRadius: 10000 }} onPress={() => setSelfInputData(pre => { return { ...pre, price: '3.00' } })} >
                                            <Text style={{ paddingHorizontal: 10, paddingVertical: 14, color: '#000', fontWeight: 'bold', fontSize: 16 }} >3.00</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ backgroundColor: '#fff', borderRadius: 10000, marginLeft: 8 }} onPress={() => setSelfInputData(pre => { return { ...pre, price: '4.00' } })} >
                                            <Text style={{ paddingHorizontal: 10, paddingVertical: 14, color: '#000', fontWeight: 'bold', fontSize: 16 }} >4.00</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <TouchableOpacity style={{ width: '80%', backgroundColor: '#000', borderRadius: 8, alignItems: 'center', marginVertical: 8, marginLeft: '10%' }}
                                        onPress={addSelfInput}
                                    >
                                        <Text style={{ color: '#fff', fontSize: 16, fontWeight: 'bold', paddingVertical: 8 }} >OK</Text>
                                    </TouchableOpacity>

                                </View>
                            </View>
                            <TouchableOpacity
                                style={[styles.operationButtons, { backgroundColor: rightSideItemsShow.showSelfInput ? 'black' : '#fff' }]}
                                onPress={() => {
                                    setRightSideItemsShow(prevState => {
                                        const show = prevState.showSelfInput;
                                        return {
                                            ...prevState,
                                            showOrderType: false,
                                            tipModalVisible: false,
                                            showTimeVisible: false,
                                            showDiscount: false,
                                            showSelfInput: !show,
                                            showPayment: false
                                        }
                                    })
                                }}>
                                <Feather name='edit' size={30} color={rightSideItemsShow.showSelfInput ? '#fff' : '#494949'} />
                                <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 4, color: rightSideItemsShow.showSelfInput ? '#fff' : '#494949' }}>INPUT</Text>
                            </TouchableOpacity>


                            <TouchableOpacity
                                style={styles.operationButtons}
                                onPress={() => {
                                    dispatch(cartAction.clearItems());
                                    setSelectedRowId(null);
                                }}>
                                <Fontisto name='redo' size={30} color='#494949' />
                                <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 4, color: '#494949' }}>RESET</Text>
                            </TouchableOpacity>


                            <View style={{ zIndex: 10000, display: rightSideItemsShow.showPayment ? 'flex' : 'none', }} >
                                <View style={{ position: 'absolute', left: -5, top: 15, height: 30, borderBottomWidth: 20, borderLeftWidth: 20, borderTopWidth: 20, borderBottomColor: 'transparent', borderTopColor: 'transparent', borderLeftColor: '#4285F4', zIndex: 100000 }}>
                                </View>
                                <View style={{ position: 'absolute', left: -450, bottom: -67 }} >
                                    <PaymentView sendMessage={sendMessage}/>
                                </View>
                            </View>

                            <TouchableOpacity
                                style={[styles.operationButtons, { backgroundColor: rightSideItemsShow.showPayment ? 'black' : '#fff' }]}
                                onPress={() => {
                                    setRightSideItemsShow(prevState => {
                                        const show = prevState.showPayment;
                                        return {
                                            ...prevState,
                                            showOrderType: false,
                                            tipModalVisible: false,
                                            showTimeVisible: false,
                                            showDiscount: false,
                                            showSelfInput: false,
                                            showPayment: !show
                                        }
                                    })
                                }}>
                                <MaterialIcons name='payment' size={30} color={rightSideItemsShow.showPayment ? '#fff' : '#494949'} />
                                <Text style={{ fontWeight: 'bold', fontSize: 13, marginTop: 4, color: rightSideItemsShow.showPayment ? '#fff' : '#494949' }}>PAY</Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                </View>

                <Portal style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Dialog style={styles.addItemModal} visible={visible} onDismiss={hideModal}>
                        {curruntItem && <ItemDialog
                            curruntItem={curruntItem}
                            store={props.store}
                            hideModal={hideModal}
                            category={categories[currentCategoryIndex]}
                            scrollRef={scrollRef}
                            rePositionSelectedRow={rePositionSelectedRow}
                        />}
                    </Dialog>
                </Portal>

            </View>

        </View >
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignContent: 'center',
        backgroundColor: 'white'
    },
    addItemModal: {
        maxWidth: 560,
        width: 560,
        maxHeight: Dimensions.get('window').height - Dimensions.get('window').height * 0.10,
        justifyContent: 'center',
        alignSelf: 'center',
        backgroundColor: 'white',
        borderRadius: 16,
    },
    operationButtons: { alignItems: 'center', justifyContent: 'center', width: 66, height: 66, backgroundColor: 'white', marginBottom: 24, borderTopRightRadius: 20, borderBottomEndRadius: 20, marginLeft: -1 }
});

export default Index;