import React from "react";
import { Dimensions, View, TouchableOpacity, Text, StyleSheet, ScrollView } from 'react-native'
import {
    GoogleMap,
    DirectionsRenderer,
    LoadScript
} from "@react-google-maps/api";
import { MaterialIcons } from '@expo/vector-icons'
import { request } from '../until/request'
import Constant from "../until/constant";
// import '../css/webMap.css'

const lib = ["places"];
const key = "AIzaSyALsR-zXZGnuKIdwSvfIv1qtYHj_JgSahw";

// const defaultLocation = { lat: 26.055986, lng: 119.339237 };
// let origin = { lat: 38.9714702, lng: -94.75924909999999 };
let directionsService;
let geocoder;

class Map extends React.Component {
    // geocoder = new google.maps.Geocoder();
    state = {
        directions: null,
        bounds: null,
        width: Dimensions.get('window').width,
        tableData: [],
        origin: { lat: 26.055986, lng: 119.339237 }
    };

    componentDidMount() {
        if (this.props.route.params.isOrders) {
            request(`${Constant.endPointUrl}/secure/api/tapthru/getNonDeliveredOrders?restaurantInfoId=3`, {
                method: 'POST'
            }).then(response => response.json())
                .then(responseText => {
                    this.setState({ tableData: responseText })
                })
        }

    }

    onMapLoad = map => {
        directionsService = new google.maps.DirectionsService();
        geocoder = new google.maps.Geocoder();
        //load default origin and destination
        geocoder.geocode({ 'address': '福州市台江区鳌祥佳园' }, (results, status) => {
            console.log(results[0].geometry.location.lat())
            console.log(results[0].geometry.location.lng())
            this.setState({ origin: { lat: Number(results[0].geometry.location.lat()), lng: Number(results[0].geometry.location.lng()) } })
        })
        // this.changeDirection(origin, destination);
        if (!this.props.route.params.isOrders) {
            this.changeDirection(this.props.route.params)
        }
    };

    //function that is calling the directions service
    changeDirection = (item) => {
        var destination = {}
        geocoder.geocode({ 'address': item.customer.address + ',' + item.customer.city + ',' + item.customer.state + ',' + item.customer.zip }, (results, status) => {
            if (status === 'OK') {
                destination.lat = results[0].geometry.location.lat()
                destination.lng = results[0].geometry.location.lng()
            }
        })
        directionsService.route(
            {
                origin: this.state.origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    //changing the state of directions to the result of direction service
                    this.setState({
                        directions: result
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    };

    render() {
        return (
            <LoadScript googleMapsApiKey={key} libraries={lib} >
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }} >
                    <View style={{ borderColor: 'rgb(153,153,153)', padding: 8, borderRadius: 5, borderWidth: 1 }} >
                        <GoogleMap
                            center={this.state.origin}
                            zoom={14}
                            onLoad={map => this.onMapLoad(map)}
                            mapContainerStyle={{ height: "600px", width: "1000px" }}
                        >
                            {this.state.directions !== null && (
                                <DirectionsRenderer directions={this.state.directions} />
                            )}
                        </GoogleMap>
                    </View>
                    {
                        this.props.route.params.isOrders ?
                            <View style={styles.orderListView} >
                                <ScrollView horizontal={true} >
                                    {
                                        this.state.tableData.map((item, index) => {
                                            return (
                                                <TouchableOpacity style={styles.buttonView} key={index} onPress={() => this.changeDirection(item)} >
                                                    <Text style={styles.Refont}>{item.ticketNumber}</Text>
                                                    <Text style={styles.Refont}>Delivery</Text>
                                                    <Text style={styles.Refont}>${item.total}</Text>
                                                </TouchableOpacity>
                                            )
                                        })
                                    }
                                </ScrollView>
                                <TouchableOpacity style={[styles.buttonView, { width: 75, backgroundColor: '#ff9999' }]} onPress={() => this.props.navigation.goBack()} >
                                    <MaterialIcons name='exit-to-app' size={23} color='black' />
                                    <Text style={styles.Refont}>Exit</Text>
                                </TouchableOpacity>
                            </View>
                            :
                            <TouchableOpacity style={[styles.buttonView, { width: 75, backgroundColor: '#ff9999', marginTop:10 }]} onPress={() => this.props.navigation.goBack()} >
                                <MaterialIcons name='exit-to-app' size={23} color='black' />
                                <Text style={styles.Refont}>Exit</Text>
                            </TouchableOpacity>
                    }
                </View>
            </LoadScript>
        );
    }
}

const styles = StyleSheet.create({
    orderListView: {
        width: 1018,
        padding: 10,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: 'rgb(153, 153, 153)',
        marginTop: 10,
        flexDirection: 'row'
    },
    buttonView: {
        width: 82,
        height: 72,
        borderRadius: 3,
        backgroundColor: '#99CCFF',
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 4
    },
    Refont: {
        fontFamily: 'Regular',
        fontSize: 16
    }
})

export default Map;