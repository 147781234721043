import React, {useEffect, useState} from 'react'
import {SipProvider, callPropType} from "@evercall/react-sip";
import IndexView from '../views/index'
import {Button, Modal} from "react-bootstrap";
import {Text, TouchableOpacity, View} from "react-native";
import {TextInput} from "react-native-paper";

const SipProviderView = (props, context) => {

    const [sipUser, setSipUser] = useState('')
    const [tempSipUser, setTempSipUser] = useState('')
    const [showSipUserModal, setShowSipUserModal] = useState(false)
    const Sip = context;

    useEffect(() => {
        if (localStorage.showSipUserModal === "true") {
            setShowSipUserModal(true)
        }
    })

    return (
        <SipProvider
            host="pbx.tapthru.com"
            port={8089}
            secure={true}
            pathname="/ws" // Path in socket URI (e.g. wss://sip.example.com:7443/ws); "" by default
            user={localStorage.sipUser} // 这个之后需要登录框获取
            password={'tapthru123'} // 这个之后需要登录框获取
            autoRegister={true} // true by default, see jssip.UA option register
            autoAnswer={false} // automatically answer incoming calls; false by default
            iceRestart={false} // force ICE session to restart on every WebRTC call; false by default
            sessionTimersExpires={120} // value for Session-Expires header; 120 by default
            debug={true} // whether to output events to console; false by default
        >
            <IndexView {...props} />

            <Modal show={showSipUserModal} centered onHide={() => setShowSipUserModal(false)}  >
                <Modal.Header closeButton>Log into Phone</Modal.Header>
                <Modal.Body>
                            <View style={{ marginTop: 10, alignItems: 'center' }}>
                                <Text style={{ fontSize: 16, paddingVertical: 10 }} >Extension</Text>
                                <TextInput
                                    mode="outlined"
                                    theme={{
                                        colors: {
                                            primary: "rgb(200,200,200)",
                                            background: 'white',
                                            text: 'black'
                                        }
                                    }}
                                    style={{
                                        height: 40
                                    }}
                                    outlineColor={"rgb(200,200,200)"}
                                    onChangeText={setTempSipUser}
                                    value={tempSipUser} />
                            </View>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => {
                            setShowSipUserModal(false);
                            localStorage.sipUser = tempSipUser;
                            localStorage.showSipUserModal = "false";
                        }}
                        variant="primary">
                        OK
                    </Button>
                    <Button
                        onClick={() => {
                            setShowSipUserModal(false);
                            localStorage.showSipUserModal = "false";
                        }}
                        variant="secondary">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </SipProvider>
    )
}

export default SipProviderView